<template>
  <v-dialog
    :value="mostrar"
    content-class="dialog-detalhe-usuario teste overflow-hidden"
    @click:outside="$emit('fechar')"
  >
    <v-card>
      <v-toolbar color="grey lighten-3" flat dense>
        <v-toolbar-title> Detalhes usuário </v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-btn text small @click="$emit('fechar')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <template v-if="usuario">
        <v-row class="px-5 py-3 position-responsive">
          <!-- <v-progress-linear
        indeterminate
    v-if="teste"
        
        color="primary"
    ></v-progress-linear> -->
          <v-col cols="12" sm="6" order="1" class="d-flex justify-center align-center">
            <img
              class="imagemUsuario"
              v-if="usuario.imagem_usuario"
              :src="caminhoImagemUsuario + usuario.imagem_usuario"
            />
          </v-col>
          <v-col cols="12" sm="6" order="2" class="card-info">
            <h3>Usuário</h3>
            <p>Nome do usuário: {{ usuario.nome_usuario || "" }}</p>
            <p>Nome da pessoa: {{ usuario.pessoa.nome || "" }}</p>
            <p>
              Data de nascimento: {{ dataFormatada || "" }}
            </p>
            <p>Apelido: {{ usuario.pessoa.apelido || "" }}</p>
            <p>Telefone: {{ usuario.pessoa.telefone || "" }}</p>
            <p>Cpf: {{ usuario.pessoa.cpf || "" }}</p>
            <p>Contato: {{ usuario.pessoa.contato || "" }}</p>
          </v-col>
          <span class="divider" />
          <v-col cols="12" sm="6" order="4" class="card-info"  v-if="empresaInfo">
            <h3>Empresa</h3>
            <p>Nome da empresa: {{ empresaInfo.data.descricao || "" }}</p>
            <p>Logradouro: {{ empresaInfo.data.endereco.logradouro || "" }}</p>
            <p>Número: {{ empresaInfo.data.endereco.numero || "" }}</p>
            <p>
              Complemento: {{ empresaInfo.data.endereco.complemento || "" }}
            </p>
            <p>Cep: {{ empresaInfo.data.endereco.cep || "" }}</p>
            <p>Bairro: {{ empresaInfo.data.endereco.bairro || "" }}</p>
            <p>
              Cidade: {{ empresaInfo.data.endereco.cidade.descricao || "" }}
            </p>
            <p>
              Cep da cidade: {{ empresaInfo.data.endereco.cidade.cep || "" }}
            </p>
            <p>
              Estado:
              {{ empresaInfo.data.endereco.cidade.estado.descricao || "" }}
            </p>
          </v-col>
          <v-col cols="12" sm="6"  order="3" class="d-flex justify-center align-center">
            <img
              class="imagemUsuario"
              v-if="empresaInfo.data.imagem"
              :src="caminhoImagensEmpresa + empresaInfo.data.imagem"
            />
          </v-col>
        </v-row>
      </template>
    </v-card>
  </v-dialog>
</template>

<script>
import formatarData from "@/utils/formatar-data-input";

export default {
  props: {
    mostrar: {
      type: Boolean,
      default: false
    },
    usuario: {
      type: Object,
      default: () => {}
    },
    empresaInfo: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      caminhoImagemUsuario: process.env.VUE_APP_URL_SERVIDOR + "storage/imagens_usuario/",
      caminhoImagensEmpresa: process.env.VUE_APP_URL_SERVIDOR + 'storage/imagens_empresas/',
      carregandoEmpresa: false,
      dataFormatada: null
    };
  },

    watch: {
    "usuario.pessoa.data_nascimento": {
      handler() {
        this.buscarAcessos()
      }
    },
  },
 methods: {
    async buscarAcessos() {
      try {
        this.dataFormatada = formatarData.ptBr(this.usuario.pessoa.data_nascimento)
      } catch (e) {
        console.log(e)
      }
    },
 }

};
</script>
<style>
.v-dialog {
  max-width: 1100px;
}
.position-responsive {
  position: relative;
}
.imagemUsuario {
  width: 100px;
  height: 100px;
}
.card-info  p {
  margin-bottom: 10px!important;
}
.divider {
  content: "";
  width: 90%;
  left: 5%;
  top: 47%;
  position: absolute;
  border-bottom: solid 1px #c0b5b5;
}
</style>
